import { AppState, Auth0Provider, Auth0ProviderOptions, useAuth0 } from '@auth0/auth0-react';
import loadable from '@loadable/component';
import { EVENTS } from '@shared/constants/events';
import AppShell from '@shared/react_components/AppShell';
import Loading from '@shared/react_components/Loading';
import { Toaster } from '@shared/react_components/Toaster';
import { TooltipProvider } from '@shared/react_components/Tooltip';
import { getConfig } from '@shared/utils/config';
import React, { Suspense, useCallback, useLayoutEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Account from './Account';
import ProtectedRoute from './ProtectedRoute';

// ECP View Routes
const AccountSettingsScreen = loadable(() => import(/* webpackChunkName: "AccountSettings" */ './screens/account-settings'));
const CreateOrderScreen = loadable(() => import(/* webpackChunkName: "CreateOrder" */ './screens/create-order'));
const DraftsScreen = loadable(() => import(/* webpackChunkName: "Drafts" */ './screens/drafts'));
const OrdersScreen = loadable(() => import(/* webpackChunkName: "Orders" */ './screens/orders'));
const OrderDetails = loadable(() => import(/* webpackChunkName: "OrderDetails" */ './screens/orders/order_details'));
const PackingSlip = loadable(() => import(/* webpackChunkName: "PackingSlip" */ './screens/orders/order_details/packing_slip'));
const PointOfSale = loadable(() => import(/* webpackChunkName: "PointOfSale" */ './screens/point-of-sale'));
const LabPayScreen = loadable(() => import(/* webpackChunkName: "Payments" */ './screens/lab-pay'));
const RewardsScreen = loadable(() => import(/* webpackChunkName: "Rewards" */ './screens/rewards'));
const ReportsScreen = loadable(() => import(/* webpackChunkName: "Reports" */ './screens/reports'));

// Lab View Routes
const ECPSScreen = loadable(() => import(/* webpackChunkName: "Lab ECPs" */ './screens/labs/ecps'));
const ECPDetailsScreen = loadable(() => import(/* webpackChunkName: "Lab ECP Details" */ './screens/labs/ecps/ecp_management'));
const LabPaymentsScreen = loadable(() => import(/* webpackChunkName: "Lab Payments" */ './screens/labs/payments'));
const LabReportsScreen = loadable(() => import(/* webpackChunkName: "Lab Reports" */ './screens/labs/reports'));
const LabSettingsScreen = loadable(() => import(/* webpackChunkName: "Lab Settings" */ './screens/labs/settings'));

// App Wide Screens
const ErrorScreen = loadable(() => import(/* webpackChunkName: "Error Screen" */ './modules/shared/react_components/ErrorScreen'));

const config = getConfig();
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Auth0ProviderWithRedirectCallback />} errorElement={<ErrorScreen title="Something went wrong" />}>
      <Route path="/" element={<Navigate replace to="/account" />} />
      <Route path="/account" element={<Account />} />
      <Route path="/ecp/:ecp_id/lab/:lab_id" element={<ProtectedRoute component={AppShell} />}>
        <Route index element={<Navigate replace to="orders" />} />
        <Route path="create-order" element={<ProtectedRoute component={CreateOrderScreen} />} />
        <Route path="draft-orders" element={<ProtectedRoute component={DraftsScreen} />} />
        <Route path="lab-pay" element={<ProtectedRoute component={LabPayScreen} />} />
        <Route path="orders">
          <Route index element={<ProtectedRoute component={OrdersScreen} />} />
          <Route path=":order_id">
            <Route index element={<ProtectedRoute component={OrderDetails} />} />
            <Route path="packing-slip" element={<ProtectedRoute component={PackingSlip} />} />
          </Route>
        </Route>
        <Route path="point-of-sale/*" element={<ProtectedRoute component={PointOfSale} />} />
        <Route path="settings/*" element={<ProtectedRoute component={AccountSettingsScreen} />} />
        <Route path="rewards" element={<ProtectedRoute component={RewardsScreen} />} />
        <Route path="reports" element={<ProtectedRoute component={ReportsScreen} />} />
        <Route path="*" element={<Navigate replace to="/orders" />} />
      </Route>
      <Route path="/lab/:lab_id" element={<ProtectedRoute component={AppShell} />}>
        <Route index element={<Navigate replace to="ecps" />} />
        <Route path="ecps">
          <Route index element={<ProtectedRoute component={ECPSScreen} />} />
          <Route path=":ecp_id/*" element={<ProtectedRoute component={ECPDetailsScreen} />} />
        </Route>
        <Route path="payments" element={<ProtectedRoute component={LabPaymentsScreen} />} />
        <Route path="reports/*" element={<ProtectedRoute component={LabReportsScreen} />} />
        <Route path="settings/*" element={<ProtectedRoute component={LabSettingsScreen} />} />
        <Route path="*" element={<Navigate replace to="/ecps" />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />
    </Route>
  )
);

const pathsToSkipAuthCallback = ['lab/:lab_id/settings/payments'];
function useSkipAuthCallbackRouteMatcher() {
  const { pathname } = useLocation();
  const shouldSkip = pathsToSkipAuthCallback.find((path) => {
    return Boolean(matchPath({ path }, pathname));
  });
  return Boolean(shouldSkip);
}

function Auth0ProviderWithRedirectCallback() {
  const navigate = useNavigate();
  const shouldSkip = useSkipAuthCallbackRouteMatcher();

  function onRedirectCallback(appState: AppState | undefined) {
    const url = (appState && appState?.returnTo) || window.location.pathname;
    navigate(url);
  }

  const providerProps: Auth0ProviderOptions = {
    clientId: config.auth.clientId,
    domain: config.auth.domain,
    authorizationParams: {
      skipRedirectCallback: shouldSkip,
      audience: config.auth.audience,
      redirect_uri: config.auth.redirectUri,
    },
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...providerProps}>
      <Root />
    </Auth0Provider>
  );
}

function Root() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const screen_hint = queryParams.get('screen_hint') || undefined;
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

  const handleIsNotAuthed = useCallback(async () => {
    await loginWithRedirect({
      authorizationParams: {
        redirect_uri: config.auth.redirectUri,
        screen_hint,
      },
    });
  }, [loginWithRedirect, screen_hint]);

  useLayoutEffect(() => {
    if (!isAuthenticated && !isLoading) {
      // We're not authed. Redirect to login if so.
      handleIsNotAuthed();
      return;
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return (
      <div className="tw-flex tw-grow tw-items-center tw-justify-center tw-h-full pt-3 bg-accent">
        <Loading />
      </div>
    );
  } else if (error) {
    return <ErrorScreen title="Authentication Failed" source="Root" event={EVENTS.AUTHNZ_ERROR} />;
  }
  return <Outlet />;
}

export default function App() {
  return (
    <HelmetProvider>
      <Suspense fallback={<p>Loading....</p>}>
        <Toaster />
        <TooltipProvider>
          <RouterProvider router={router} />
        </TooltipProvider>
      </Suspense>
    </HelmetProvider>
  );
}
