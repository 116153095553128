import React from 'react';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import DropdownItem from '@shared/react_components/DropdownItem';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shared/react_components/DropdownMenu';
import { useAuth0 } from '@auth0/auth0-react';

import LogoutIcon from '@assets/icons/Logout-24-Black.svg';
import { IUserMetadata } from '@shared/models/user-metadata.model';

function getAvatarInitials(user: IUserMetadata) {
  const name = user.user_name;

  if (!name) {
    return '😀';
  }

  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
}

export default function Avatar() {
  const { data: populatedUser } = usePopulateUser();
  const { logout } = useAuth0();

  function handleLogout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  if (!populatedUser) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          id="sc-avatar"
          className="tw-appearance-none tw-shadow-none tw-border-none tw-rounded-full tw-bg-primary-100 tw-w-7 tw-h-7 md:tw-w-9 md:tw-h-9 tw-flex tw-items-center tw-justify-center tw-flex-row"
        >
          <span className="tw-text-primary-700 tw-text-sm tw-font-semibold">{getAvatarInitials(populatedUser)}</span>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel className="!tw-py-0 !tw-px-1">
          <span className="tw-text-sm tw-text-neutral-600 tw-font-normal">{populatedUser.user_email}</span>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownItem icon={<LogoutIcon />} label="Logout" onClick={handleLogout} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
